import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getUserData } from 'api/user';

export type AuthState = {
    email: string;
    name: string;
    roleName: string;
    uuid: string;
    token: string;
    loading: boolean;
    success: boolean | null;
};

export type AuthRequest = {
    email: string;
    password: string;
};

/**
 * calls login API
 **/
export const fetchUserData = createAsyncThunk('users/fetchUserData', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getUserData();
        const { data = {} } = response;

        if (data.success === true) {
            dispatch(setAuth(data.data));
            return true;
        }

        return rejectWithValue(false);
    } catch (error: any) {
        const { status } = error.response || {};

        if (status === 401) {
            dispatch(logout());
        }

        return rejectWithValue(false);
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        email: '',
        name: '',
        roleName: '',
        uuid: '',
        token: '',
        loading: false,
        success: null,
    } as AuthState,
    reducers: {
        setAuth: (state: AuthState, action) => {
            const { token, email, name, uuid, role_name } = action.payload;
            state.email = email;
            state.name = name;
            state.uuid = uuid;
            state.roleName = role_name;

            if (token) {
                state.token = token;
            }
        },
        logout: (state: AuthState) => {
            state.email = '';
            state.name = '';
            state.uuid = '';
            state.token = '';
            state.roleName = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.pending, (state: AuthState) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.fulfilled, (state: AuthState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchUserData.rejected, (state: AuthState) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const authSelector = (state: RootState) => state.auth;
export const { setAuth, logout } = authSlice.actions;
