import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAppSelector } from 'app/hooks';

import { Header } from 'antd/lib/layout/layout';
import { Layout } from 'antd';
import { authSelector } from 'app/slice/authSlice';
import { useStyle } from 'lib/utils';

const MainLayout = () => {
    const navigate = useNavigate();
    const { token, roleName } = useAppSelector(authSelector);

    useStyle('labor-social');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    useEffect(() => {
        if (roleName) {
            navigate('/dashboard/meeting');
        }
    }, [token, navigate, roleName]);

    return (
        <Layout>
            <Header className='labor-social-navbar'>
                <div className='title'>
                    <span>中企団 案件ご紹介管理サイト</span>
                </div>
            </Header>
            <Outlet />
        </Layout>
    );
};

export default MainLayout;
