import { SubmitPolicyScheduleRequest, SubmitScheduleRequest } from 'app/slice/meetingSlice';
import { getAxiosClientWithToken } from './index';

export const getScheduledMeetings = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/scheduled/meetings');
};

export const submitScheduleApi = (data: SubmitScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/specialist/application/' + data.uuid + '/schedule', {
        dates: data.dates,
        person_in_charge: data.personInCharge,
    });
};

export const submitPolicyScheduleApi = (data: SubmitPolicyScheduleRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/specialist/policies/applications/' + data.uuid + '/schedule', {
        dates: data.dates,
        person_in_charge: data.personInCharge,
    });
};
