import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { useCallback } from 'react';
import { useAppDispatch } from 'app/hooks';

export interface LoaderState {
    count: number;
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        count: 0
    },
    reducers: {
        setLoader: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.count += action.payload ? 1 : -1;
        }
    }
});

export const { setLoader } = loaderSlice.actions;
export const selectLoader = (state: RootState) => state.loader;

export const useLoader = (func: () => Promise<any>) => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        return new Promise(async resolve => {
            dispatch(setLoader(true));
            let value = await func();
            dispatch(setLoader(false));
            resolve(value);
        });
    }, [dispatch, func]);
};
