import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Company, UpdatePersonInChargeRequest } from 'app/slice/applicationSlice';
import {
    addCompanyPolicyCommentApi,
    deleteCompanyPolicyVerificationApi,
    getCompanyPolicyCommentApi,
    getCompanyPolicyLogsApi,
    getPolicyApi,
    getPolicyListApi,
    getPolicyStatusApi,
    submitPolicyApi,
    updateRepresentativeName,
} from 'api/policy';
import { getErrorMessage } from 'api';
import { AxiosError } from 'axios';

export const GET_POLICY_LIST = 'GET_POLICY_LIST';
export const GET_POLICY = 'GET_POLICY';
export const SUBMIT_POLICY = 'SUBMIT_POLICY';
export const DELETE_VERIFICATION_FILE = 'DELETE_VERIFICATION_FILE';
export const SUBMIT_POLICY_COMMENT = 'SUBMIT_POLICY_COMMENT';
export const UPDATE_REPRESENTATIVE_NAME = 'UPDATE_REPRESENTATIVE_NAME';
// Company Policy Topic Structure
export type Topic = {
    code: string,
    name: string,
}

export type Verification = {
    uuid: string,
    downloaded: number,
    downloadedAt: string,
    downloadUrl: string,
    previewUrl: string,
    representativeName: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
}

export type PolicyFile = {
    policyId: number,
    uuid: string,
    name: string,
    createdAt: string,
    downloadUrl: string,
    downloaded: number,
    fileType: string,
    isPolicy: number,
    previewUrl: string,
    updatedAt: string,
    representativeName?: string,
    storagePeriod?: string,
    dateAdded: string,
    deletedAt: string | null
}

export type PolicyData = {
    uuid: string,
    company: Company,
    verification: Verification | null,
    policyFiles: PolicyFile[],
    name: string,
    status: string,
    shared: boolean,
    sharedAt: string,
    dueDateAt: string,
    downloaded: boolean,
    downloadedAt: string,
    updatedAt: string,
    deletedAt: string,
}

export type PolicyType = {
    policyList: PolicyData[],
    policy: PolicyData,
    //Topic and Policy Status have the same type values
    policyStatus: Topic[],
    loading: boolean,
    success: boolean,
    type: string,
    policyLogRecords: PolicyLogData[],
    policyComment: PolicyComment,
    pagination: Pagination
}

export type GetPolicyListRequest = {
    keywords: string,
    page: number,
    status: number,
}

export type SubmitPolicyRequest = {
    uuid: string,
    data: FormData
}
export type PolicyLogData = {
    uuid: string,
    name: string,
    action: string,
    createdAt: string
}

export type PolicyComment = {
    comment: string,
    fullName: string,
    updatedAt: string
}

export type AddCompanyPolicyCommentRequest = {
    uuid: string,
    comment: string
}

export type PolicyFilesResponse = {
    policy_id: number,
    uuid: string,
    name: string,
    created_at: string,
    download_url: string,
    downloaded: number,
    file_type: string,
    is_policy: number,
    preview_url: string,
    updated_at: string,
    deleted_at: string | null
}

type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

/**
 * Get Policy List
 **/
export const getPolicyList = createAsyncThunk(
    '/specialist/policies',
    async (data: GetPolicyListRequest, { dispatch }) => {
        try {
            const response = await getPolicyListApi(data);
            dispatch(setPolicyList(response.data));
            dispatch(setPagination(response.data.pagination));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Get Policy List
 **/
export const getPolicy = createAsyncThunk(
    '/specialist/policies/:uuid',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getPolicyApi(uuid);
            dispatch(setPolicy(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Get Policy Status
 **/
export const getPolicyStatus = createAsyncThunk(
    '/specialist/policies/statuses',
    async (_, { dispatch }) => {
        try {
            const response = await getPolicyStatusApi();
            dispatch(setPolicyStatus(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Submit Policy Status
 **/
export const submitPolicy = createAsyncThunk(
    '/specialist/policies/submit',
    async (data: SubmitPolicyRequest) => {
        try {
            const response = await submitPolicyApi(data.uuid, data.data);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Submit Policy Status
 **/
export const deleteVerificationFile = createAsyncThunk(
    '/specialist/policies/verification/delete',
    async (uuid: string) => {
        try {
            const response = await deleteCompanyPolicyVerificationApi(uuid);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Fetch Company Policy Logs
 **/
export const getCompanyPolicyLogs = createAsyncThunk(
    '/specialist/policies/verification/get',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getCompanyPolicyLogsApi(uuid);
            dispatch(setPolicyLog(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Fetch Company Policy Comment
 **/
export const getCompanyPolicyComment = createAsyncThunk(
    '/specialist/policies/comment/get',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getCompanyPolicyCommentApi(uuid);
            dispatch(setPolicyComment(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Add Company Policy Comment
 **/
export const addCompanyPolicyComment = createAsyncThunk(
    '/specialist/policies/comment/add',
    async (request: AddCompanyPolicyCommentRequest, { dispatch }) => {
        try {
            const response = await addCompanyPolicyCommentApi(request);
            dispatch(setPolicyComment(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export const policyPersonInChargeUpdate = createAsyncThunk(
    '/specialist/policy/personInCharge',
    async (request: UpdatePersonInChargeRequest, { rejectWithValue }) => {
        try {
            const response = await updateRepresentativeName(request);
            return response.data;
        } catch (err) {
            return rejectWithValue(getErrorMessage(err as AxiosError))
        }
    },
);

/**
 * Create Policy Slice
 */
export const policySlice = createSlice({
    name: 'policies',
    initialState: {
        policyList: [],
        policy: {} as PolicyData,
        //Topic and Policy Status have the same type values
        policyStatus: [] as Topic[],
        loading: false,
        success: false,
        policyLogRecords: [] as PolicyLogData[],
        type: '',
        policyComment: {} as PolicyComment,
        pagination: {} as Pagination
    } as PolicyType,
    reducers: {
        setPolicyList: (state, { payload }) => {
            state.policyList = [];
            for (let policy of payload.data) {
                state.policyList.push({
                    uuid: policy?.uuid,
                    company: {
                        name: policy?.company?.name,
                        address: policy?.company?.address,
                        industry: policy?.company?.industry,
                        laborRegulationsCompliant: policy?.company?.labor_regulations_compliant,
                        allowSharoushi: policy?.company?.allow_sharoushi,
                        allowShindanshi: policy?.company?.allow_shindanshi,
                        insurances: policy?.company?.insurances,
                        userUuid: policy?.company?.user_uuid,
                    },
                    verification: policy?.verification_file ? {
                        uuid: policy?.verification_file?.uuid,
                        downloaded: policy?.verification_file?.downloaded,
                        downloadedAt: policy?.verification_file?.downloaded_at,
                        downloadUrl: policy?.verification_file?.download_url,
                        previewUrl: policy?.verification_file?.preview_url,
                        representativeName: policy?.verification_file?.representative_name,
                        createdAt: policy?.verification_file?.created_at,
                        updatedAt: policy?.verification_file?.updated_at,
                        deletedAt: policy?.verification_file?.deleted_at,
                    } : null,
                    policyFiles: policy?.policy_files?.map((item: PolicyFilesResponse) => ({
                        policyId: item?.policy_id,
                        uuid: item?.uuid,
                        name: item?.name,
                        createdAt: item?.created_at,
                        downloadUrl: item?.download_url,
                        downloaded: item?.downloaded,
                        fileType: item?.file_type,
                        isPolicy: item?.is_policy,
                        previewUrl: item?.preview_url,
                        updatedAt: item?.updated_at,
                        deletedAt: item?.deleted_at
                    })),
                    status: policy?.status,
                    shared: policy?.shared,
                    sharedAt: policy?.shared_at,
                    dueDateAt: policy?.due_date_at,
                    downloaded: policy?.shared_at,
                    downloadedAt: policy?.downloaded_at,
                    updatedAt: policy?.updated_at,
                    deletedAt: policy?.deleted_at,
                    name: policy?.policy_files?.map((item: PolicyFilesResponse) => item.name).join()
                });
            }
        },
        setPolicyLog: (state, { payload }) => {
            state.policyLogRecords = [];
            for (let policyLog of payload.data) {
                state.policyLogRecords.push({
                    uuid: policyLog?.uuid,
                    name: policyLog?.name,
                    action: policyLog?.action,
                    createdAt: policyLog?.created_at,
                });
            }
        },

        setPolicyComment: (state, { payload }) => {
            state.policyComment = {
                comment: payload?.data?.comment,
                updatedAt: payload?.data?.updated_at,
                fullName: payload?.data?.fullname,
            };
        },

        setPolicy: (state, { payload }) => {
            state.policy = {
                uuid: payload.data?.uuid,
                company: {
                    name: payload.data?.company?.name,
                    address: payload.data?.company?.address,
                    industry: payload.data?.company?.industry,
                    laborRegulationsCompliant: payload.data?.company?.labor_regulations_compliant,
                    allowSharoushi: payload.data?.company?.allow_sharoushi,
                    allowShindanshi: payload.data?.company?.allow_shindanshi,
                    insurances: payload.data?.company?.insurances,
                },
                verification: payload.data?.verification_file ? {
                    uuid: payload.data?.verification_file?.uuid,
                    downloaded: payload.data?.verification_file?.downloaded,
                    downloadedAt: payload.data?.verification_file?.downloaded_at,
                    downloadUrl: payload.data?.verification_file?.download_url,
                    previewUrl: payload.data?.verification_file?.preview_url,
                    representativeName: payload.data?.verification_file?.representative_name,
                    createdAt: payload.data?.verification_file?.created_at,
                    updatedAt: payload.data?.verification_file?.updated_at,
                    deletedAt: payload.data?.verification_file?.deleted_at,
                } : null,
                policyFiles: payload.data?.policy_files?.map((item: PolicyFilesResponse) => ({
                    policyId: item?.policy_id,
                    uuid: item?.uuid,
                    name: item?.name,
                    createdAt: item?.created_at,
                    downloadUrl: item?.download_url,
                    downloaded: item?.downloaded,
                    fileType: item?.file_type,
                    isPolicy: item?.is_policy,
                    previewUrl: item?.preview_url,
                    updatedAt: item?.updated_at
                })),
                status: payload.data?.status,
                shared: payload.data?.shared,
                sharedAt: payload.data?.shared_at,
                dueDateAt: payload.data?.due_date_at,
                downloaded: payload.data?.shared_at,
                downloadedAt: payload.data?.downloaded_at,
                updatedAt: payload.data?.updated_at,
                deletedAt: payload.data?.deleted_at,
                name: payload.data?.policy_files?.map((item: PolicyFilesResponse) => item.name).join()
            };
        },

        setPolicyStatus: (state, { payload }) => {
            state.policyStatus = payload.data;
        },
        resetPolicy: (state) => {
            state.loading = false;
            state.success = false;
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                count: payload.count,
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPolicyList.pending, (state) => {
            state.type = GET_POLICY_LIST;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getPolicyList.rejected, (state) => {
            state.type = GET_POLICY_LIST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getPolicyList.fulfilled, (state) => {
            state.type = GET_POLICY_LIST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getPolicy.pending, (state) => {
            state.type = GET_POLICY;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(getPolicy.rejected, (state) => {
            state.type = GET_POLICY;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getPolicy.fulfilled, (state) => {
            state.type = GET_POLICY;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(submitPolicy.pending, (state) => {
            state.type = SUBMIT_POLICY;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(submitPolicy.rejected, (state) => {
            state.type = SUBMIT_POLICY;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(submitPolicy.fulfilled, (state) => {
            state.type = SUBMIT_POLICY;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(deleteVerificationFile.pending, (state) => {
            state.type = DELETE_VERIFICATION_FILE;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(deleteVerificationFile.rejected, (state) => {
            state.type = DELETE_VERIFICATION_FILE;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(deleteVerificationFile.fulfilled, (state) => {
            state.type = DELETE_VERIFICATION_FILE;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(addCompanyPolicyComment.pending, (state) => {
            state.type = SUBMIT_POLICY_COMMENT;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(addCompanyPolicyComment.rejected, (state) => {
            state.type = SUBMIT_POLICY_COMMENT;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(addCompanyPolicyComment.fulfilled, (state) => {
            state.type = SUBMIT_POLICY_COMMENT;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(policyPersonInChargeUpdate.pending, (state) => {
            state.type = UPDATE_REPRESENTATIVE_NAME;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(policyPersonInChargeUpdate.rejected, (state) => {
            state.type = UPDATE_REPRESENTATIVE_NAME;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(policyPersonInChargeUpdate.fulfilled, (state) => {
            state.type = UPDATE_REPRESENTATIVE_NAME;
            state.loading = false;
            state.success = true;
        });
    },
});

export const {
    setPolicyList,
    setPolicy,
    setPolicyStatus,
    resetPolicy,
    setPolicyLog,
    setPolicyComment,
    setPagination
} = policySlice.actions;
export const selectPolicy = (state: RootState) => state.policy;
