import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFunds } from 'api/funds';

export type FundDataRow = {
    code: string,
    url: string,
    type: number,
    jurisdiction: string,
    title: string,
    overview: string,
    target_groups: string,
    amount: string,
    application_period: string,
    created_at: string,
    updated_at: string
}

export type FundsListState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundDataRow[],
    params: FundsListRequest,
    page: number,
    per_page: number
}

export type FundsListRequest = {
    coverage_type?: string,
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    page?: string,
    per_page?: string
}

/**
 * calls fund API
 **/
export const getFunds = createAsyncThunk(
    'funds/search',
    async (_, { rejectWithValue, getState }) => {
        try {
            const funds = (getState() as RootState).fundsList;

            let response = await fetchFunds(funds.params);

            if (response.data.success === true) {
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fundsListSlice = createSlice({
    name: 'fundsList',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: false,
        data: [],
        params: {},
        page: 1,
        per_page: 20
    } as FundsListState,
    reducers: {
        setPage: (state: FundsListState, action) => {
            state.page = action.payload;
        },
        setPerPage: (state: FundsListState, action) => {
            state.per_page = action.payload;
        },
        setParams: (state: FundsListState, action) => {
            state.params = action.payload;
        }
    },
    extraReducers: (builder) => {
        // getFunds action pending
        builder.addCase(getFunds.pending, (state: FundsListState) => {
            state.loading = true;
            state.data = [];
        });
        // getFunds action rejected
        builder.addCase(getFunds.rejected, (state: FundsListState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFunds action fulfilled
        builder.addCase(getFunds.fulfilled, (state: FundsListState, action) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload as FundDataRow[];
        });
    }
});

export const { setPage, setPerPage, setParams } = fundsListSlice.actions;
export const selectFundsList = (state: RootState) => state.fundsList;
