import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changePasswordUser, unsubcribeUser } from 'api/company';
import { RootState } from 'app/store';

export type COMPANY_STATUS = 'success' | 'failed' | 'pending';

type CompanyState = {
    loading: boolean,
    success: boolean,
    status: COMPANY_STATUS,
    message: string
}

type unsubscribeRequest = {
    uuid: string
}

export type ChangePasswordRequest = {
    current_password: string,
    new_password: string,
    new_password_confirmation: string
}

/**
 * changePassword user
 **/
export const changePassword = createAsyncThunk(
    'company/user/password',
    async (data: ChangePasswordRequest, { getState, rejectWithValue }) => {
        const { current_password, new_password, new_password_confirmation } = data;
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await changePasswordUser(current_password, new_password, new_password_confirmation);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * unsubscribe user
 **/
export const unsubscribe = createAsyncThunk(
    'company/user',
    async (data: unsubscribeRequest, { getState, rejectWithValue }) => {
        const { uuid } = data;
        // @ts-ignore
        const { loading } = getState().company;
        if (!loading) {
            return;
        }
        try {
            const response = await unsubcribeUser(uuid);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

const initialState: CompanyState = {
    loading: false,
    success: false,
    status: 'pending',
    message: '',
};

export const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        reset: (state: CompanyState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(unsubscribe.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(unsubscribe.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(unsubscribe.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
        });
        builder.addCase(changePassword.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase(changePassword.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
        });
    },
});

export const { reset } = companySlice.actions;
export const selectCompany = (state: RootState) => state.company;