import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    cancelConsultationApi,
    CancelConsultationRequest,
    getPolicyApplicationDetailsApi,
    Label,
    Schedule,
    schedulePolicyConsultationApi,
    SchedulePolicyConsultationRequest,
} from 'api/application';
import { RootState } from 'app/store';
import { Topic } from 'app/slice/policySlice';
import { Company } from 'app/slice/applicationSlice';

export type { CancelConsultationRequest, Label, Schedule, SchedulePolicyConsultationRequest } from 'api/application';
export type { Topic } from 'app/slice/policySlice';

export const CANCEL_POLICY_APP = 'CANCEL_POLICY_APP';
export const GET_POLICY_APP = 'GET_POLICY_APP';
export const SCHEDULE_POLICY_APP = 'SCHEDULE_POLICY_APP';

// Application Details State
type PolicyApplicationDetailsState = {
    success: boolean | null,
    loading: boolean,
    data: PolicyApplicationDetails,
    type: string | null,
}

// Application Details Data Structure
export type PolicyApplicationDetails = {
    uuid: string,
    status: number,
    type: string,
    label: Label,
    schedule: string | null,
    scheduleDatetime: string | null,
    isDeadline: boolean | null,
    joinUrl: string | null,
    daidoJoins: number | null,
    password: string | null,
    chukidanSchedules: Schedule[] | null,
    topics: Topic[] | null,
    remarks: string | null,
    createdAt: string,
    cancellationReason: string | null,
    company: Company,
    personInCharge: string
}

/**
 * GET applications list
 **/
export const getPolicyApplicationDetails = createAsyncThunk(
    '/company/applications/details',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getPolicyApplicationDetailsApi(uuid);
            dispatch(setPolicyApplicationDetails(response.data));
            return response.data;

        } catch (error: any) {
            return getErrorMessage(error);
        }
    },
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const setPolicyApplicationSchedule = createAsyncThunk(
    '/company/applications/:uuid/schedule',
    async (data: SchedulePolicyConsultationRequest, { rejectWithValue }) => {
        try {
            let response = await schedulePolicyConsultationApi(data);

            if (response.data.success === true) {
                let details = response.data.data;
                details.joinUrl = details.join_url;
                details.scheduleDatetime = details.schedule_datetime;

                return details as PolicyApplicationDetails;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * PATCH Application Consultation / Meeting Schedule
 */
export const cancelPolicyApplication = createAsyncThunk(
    '/company/applications/:uuid/cancel',
    async (data: CancelConsultationRequest, { rejectWithValue }) => {
        try {
            let response = await cancelConsultationApi(data);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);


/**
 * Create Application Slice
 */
export const policyApplicationDetailsSlice = createSlice({
    name: 'application',
    initialState: {
        success: null,
        loading: true,
        data: {} as PolicyApplicationDetails,
        type: null,
    } as PolicyApplicationDetailsState,
    reducers: {
        reset: (state: PolicyApplicationDetailsState) => {
            state.success = null;
            state.loading = true;
            state.data = {} as PolicyApplicationDetails;
            state.type = null;
        },
        setPolicyApplicationDetails: (state, { payload }) => {
            state.loading = true;

            state.data.uuid = payload.data.uuid;
            state.data.type = payload.data.type;
            state.data.status = payload.data.status;
            state.data.label = payload.data.label;
            state.data.schedule = payload.data.schedule;
            state.data.password = payload.data.password;
            state.data.remarks = payload.data.remarks;
            state.data.topics = payload.data.topics;
            state.data.company = payload.data.company;

            // snake_case to camelCase
            state.data.isDeadline = payload.data.is_deadline;
            state.data.scheduleDatetime = payload.data.schedule_datetime;
            state.data.joinUrl = payload.data.join_url;
            state.data.daidoJoins = payload.data.daido_joins;
            state.data.createdAt = payload.data.created_at;
            state.data.cancellationReason = payload.data.cancellation_reason;
            state.data.personInCharge = payload.data.person_in_charge;

            if (payload.data.chukidan_schedules) {
                state.data.chukidanSchedules = payload.data.chukidan_schedules;
            }

            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        // getPolicyApplicationDetails action pending
        builder.addCase(getPolicyApplicationDetails.pending, (state: PolicyApplicationDetailsState) => {
            state.loading = true;
            state.success = null;
            state.type = GET_POLICY_APP;
        });
        // getPolicyApplicationDetails action rejected
        builder.addCase(getPolicyApplicationDetails.rejected, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = false;
            state.type = GET_POLICY_APP;
        });
        // getPolicyApplicationDetails action fulfilled
        builder.addCase(getPolicyApplicationDetails.fulfilled, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = true;
            state.type = GET_POLICY_APP;
        });
        // cancelPolicyApplication action pending
        builder.addCase(cancelPolicyApplication.pending, (state: PolicyApplicationDetailsState) => {
            state.loading = true;
            state.success = null;
            state.type = CANCEL_POLICY_APP;
        });
        // cancelPolicyApplication action rejected
        builder.addCase(cancelPolicyApplication.rejected, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = false;
            state.type = CANCEL_POLICY_APP;
        });
        // cancelPolicyApplication action fulfilled
        builder.addCase(cancelPolicyApplication.fulfilled, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = true;
            state.type = CANCEL_POLICY_APP;
        });
        // setPolicyApplicationSchedule action pending
        builder.addCase(setPolicyApplicationSchedule.pending, (state: PolicyApplicationDetailsState) => {
            state.loading = true;
            state.success = null;
            state.type = SCHEDULE_POLICY_APP;
        });
        // setPolicyApplicationSchedule action rejected
        builder.addCase(setPolicyApplicationSchedule.rejected, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = false;
            state.type = SCHEDULE_POLICY_APP;
        });
        // setPolicyApplicationSchedule action fulfilled
        builder.addCase(setPolicyApplicationSchedule.fulfilled, (state: PolicyApplicationDetailsState) => {
            state.loading = false;
            state.success = true;
            state.type = SCHEDULE_POLICY_APP;
        });
    },
});

export const { reset, setPolicyApplicationDetails } = policyApplicationDetailsSlice.actions;
export const selectPolicyApplicationDetails = (state: RootState) => state.policyApplicationDetails;
