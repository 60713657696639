import { getAxiosClientWithToken } from './index';
import { FundsListRequest } from 'app/slice/fundsListSlice';
import { FundApplicationRequest } from 'app/slice/fundApplicationSlice';

export const fetchFunds = (data: FundsListRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/funds', { params: data });
};

export const fetchFundDetails = (code: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/funds/' + code);
};

export const fetchFundRequirements = (funds: string[]) => {
    const restClient = getAxiosClientWithToken();
    const formData = new FormData();

    for (let fund of funds) {
        formData.append('funds[]', fund);
    }

    return restClient.get('/company/fund-requirements?' + new URLSearchParams(formData as any).toString());
};

export const createApplication = (funds: FundApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/company/applications', { funds });
};

export const fetchApplicationStatus = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/applications/${uuid}/status`);
};
