import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFundRequirements } from 'api/funds';

export type FundRequirementsState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundRequirementsRow[]
}

export type FundRequirementsRequest = {
    coverage_type?: string,
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    page?: string,
    per_page?: string
}

export type FundRequirementsRow = {
    code: string,
    type: number,
    title: string,
    requirements: { content: string }[]
}

export const getFundRequirements = createAsyncThunk(
    'funds/requirements',
    async (codes: string[], { rejectWithValue }) => {
        try {
            let response = await fetchFundRequirements(codes);

            if (response.data.success === true) {
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fundRequirementsSlice = createSlice({
    name: 'fundRequirements',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: false,
        data: []
    } as FundRequirementsState,
    reducers: {},
    extraReducers: (builder) => {
        // getFundRequirements action pending
        builder.addCase(getFundRequirements.pending, (state: FundRequirementsState) => {
            state.loading = true;
        });
        // getFundRequirements action rejected
        builder.addCase(getFundRequirements.rejected, (state: FundRequirementsState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFundRequirements action fulfilled
        builder.addCase(getFundRequirements.fulfilled, (state: FundRequirementsState, action) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload as FundRequirementsRow[];
        });

    }
});

export const selectFundRequirements = (state: RootState) => state.fundRequirements;
