import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getScheduledMeetings, submitPolicyScheduleApi, submitScheduleApi } from 'api/meeting';

export type Schedule = {
    schedule: string,
    companyName: string,
    uuid: string,
    type: number
};

export type ScheduleResponse = {
    schedules: {
        schedule: string,
        company_name: string,
        uuid: string,
        type: number
    }[],
    meetings_from_public_support_count: number,
    meetings_from_work_regulation_count: number,
    count_scheduled_meetings: number,
};

export type SelectedSchedulesRequest = {
    id: number,
    date: string,
    startTime: string,
    endTime: string
}

export type SelectedSchedules = {
    id: number,
    number: number,
    date: string,
    content: string,
}

export type SubmitScheduleRequest = {
    dates: string[],
    uuid: string,
    personInCharge: string
}

export type SubmitPolicyScheduleRequest = {
    uuid: string,
    dates: string[],
    personInCharge: string
}

type MeetingState = {
    loading: boolean,
    success: boolean,
    schedules: Schedule[];
    countScheduledMeetings: number;
    meetingsFromPublicSupportCount: number;
    meetingsFromWorkRegulationCount: number;
    selectedSchedules: SelectedSchedules[];
};

/**
 * GET user's scheduled meetings
 **/
export const getUserScheduledMeetings = createAsyncThunk('users/meetings', async (data, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getScheduledMeetings();
        dispatch(setScheduledMeetings(response.data));
        return response.data;
    } catch (err) {
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(false);
    }
});

export const submitSchedule = createAsyncThunk(
    'schedule/submit',
    async (data: SubmitScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await submitScheduleApi(data);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export const submitPolicySchedule = createAsyncThunk(
    'schedule/policy/submit',
    async (data: SubmitPolicyScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await submitPolicyScheduleApi(data);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

const initialState: MeetingState = {
    loading: false,
    success: false,
    schedules: [] as Schedule[],
    countScheduledMeetings: 0,
    meetingsFromPublicSupportCount: 0,
    meetingsFromWorkRegulationCount: 0,
    selectedSchedules: [] as SelectedSchedules[],
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: initialState,
    reducers: {
        setScheduledMeetings: (state, { payload }) => {
            const {
                schedules,
                meetings_from_public_support_count,
                meetings_from_work_regulation_count,
                count_scheduled_meetings,
            } = payload.data as ScheduleResponse;
            
            state.schedules = schedules.map(schedule => ({
                schedule: schedule.schedule,
                companyName: schedule.company_name,
                uuid: schedule.uuid,
                type: schedule.type
            }));
            state.countScheduledMeetings = count_scheduled_meetings;
            state.meetingsFromPublicSupportCount = meetings_from_public_support_count;
            state.meetingsFromWorkRegulationCount = meetings_from_work_regulation_count;
        },
        setSelectedMeetingSchedules: (state, { payload }) => {
            const { date, startTime, endTime } = payload;
            state.selectedSchedules.push({
                id: state.selectedSchedules.length + 1,
                number: state.selectedSchedules.length + 1,
                date: date,
                content: `${startTime}-${endTime}`,
            });
        },
        removeScheduleFromList: (state, { payload }) => {
            const newSchedule = [...state.selectedSchedules];
            // find id and remove from the array
            newSchedule.splice(state.selectedSchedules.findIndex(item => item.number === payload), 1);
            // update id of the remaining items
            let id = 1;
            newSchedule.forEach((item) => {
                item.number = id++;
            });
            state.selectedSchedules = newSchedule;
        },
        setSchedules: (state, { payload }) => {
            const schedules: SelectedSchedules[] = [];
            payload?.forEach((item: SelectedSchedules) => {
                let newDate;
                let newTime;
                let schedDateAndTime = item.content.split('　');
                if (item.content.includes('（')) {
                    const splitDate = item.content.split('）');
                    newDate = splitDate[0] + '）';
                    newTime = splitDate[1];
                } else {
                    newDate = schedDateAndTime[0];
                    newTime = schedDateAndTime[1];
                }
                schedules.push({
                    id: item.number,
                    number: item.number,
                    date: newDate,
                    content: newTime,
                });
            });

            state.selectedSchedules = schedules;
        },
        resetSelectedSchedules: (state) => {
            state.selectedSchedules = [];
        },
        resetState: (state) => {
            state.loading = false;
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitSchedule.pending, (state: MeetingState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(submitSchedule.rejected, (state: MeetingState) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(submitSchedule.fulfilled, (state: MeetingState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(submitPolicySchedule.pending, (state: MeetingState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(submitPolicySchedule.rejected, (state: MeetingState) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(submitPolicySchedule.fulfilled, (state: MeetingState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const selectMeeting = (state: RootState) => state.meeting;

export const {
    setScheduledMeetings,
    setSelectedMeetingSchedules,
    removeScheduleFromList,
    resetSelectedSchedules,
    resetState,
    setSchedules,
} = meetingSlice.actions;
