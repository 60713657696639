import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getNotifications, markingAsViewed } from 'api/notification';

type NotificationState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    notificationData: Notification[],
}

export type Notification = {
    id: number,
    message: string,
    createdAt: string,
    viewedAt: string,
    uuid: string,
    type: number,
    fundType: number
}

/**
 * GET user's notifications
 **/
export const getUserNotification = createAsyncThunk(
    'users/notification',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getNotifications();
            dispatch(setNotificationList(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export type MarkAsViewedRequest = {
    id: number
}

type NotificationData = {
    id: number,
    message: string,
    created_at: string,
    viewed_at: string,
    uuid: string,
    application: {
        uuid: string,
        type: number,
        fund_type: number
    }
}

/**
 * PATCH user's notification as viewed
 **/
export const markAsViewed = createAsyncThunk(
    'users/notification',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await markingAsViewed(id);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);


const initialState: NotificationState = {
    loading: false,
    success: false,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    notificationData: [
        {
            id: 0,
            message: '',
            createdAt: '',
            viewedAt: '',
            uuid: '',
            type: 0,
            fundType: 0,
        },
    ],
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotificationList: (state, { payload }) => {
            state.notificationData = payload.data.map((notification: NotificationData) => ({
                id: notification.id,
                message: notification.message,
                createdAt: notification.created_at,
                viewedAt: notification.viewed_at,
                uuid: notification?.application?.uuid,
                type: notification?.application?.type,
                fundType: notification?.application?.fund_type,
            }));
        },
        reset: (state: NotificationState) => {
            state.success = false;
            state.loading = false;
        },
    },
});

export const selectNotification = (state: RootState) => state.notification;

export const { reset, setNotificationList } = notificationSlice.actions;
