import { getAxiosClientWithToken } from './index';

export const changePasswordUser = (current_password: string, new_password: string, new_password_confirmation: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/update-password`, {
        current_password,
        new_password,
        new_password_confirmation,
    });
};

export const unsubcribeUser = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/company/users/${uuid}`);
};
