import 'assets/styles/app.scss';
import 'moment/locale/ja';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { authSelector, fetchUserData } from 'app/slice/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import ForgotPasswordModal from 'components/landing/ForgotPasswordModal';
import Loader from 'components/common/Loader';
import MainLayout from 'components/MainLayout';
import SuccessModal from 'components/common/SuccessModal';
import { reset } from 'app/slice/forgotPasswordSlice';

// somehow react lazy doesn't work well with HOC, need to find a work-around first
// typescript bug I guess
const Home = lazy(() => import('pages/Home'));
const ForgotPassword = lazy(() => import('pages/main/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('pages/main/ForgotPasswordSuccess'));
const DashboardLayout = lazy(() => import('pages/dashboard/DashboardLayout'));
const MeetingCalendar = lazy(() => import('pages/dashboard/MeetingCalendar'));
const Profile = lazy(() => import('pages/dashboard/Profile'));
const ConsultationListCard = lazy(() => import('pages/dashboard/ConsultationList'));
const ConsultationDetails = lazy(() => import('pages/dashboard/ConsultationDetails'));
const Forbidden = lazy(() => import('pages/main/Forbidden'));
const Diagnose = lazy(() => import('pages/dashboard/Diagnose'));
const DiagnoseManagement = lazy(() => import('pages/dashboard/DiagnoseManagement'));
const WorkRegulationMeetingManagement = lazy(() => import('pages/dashboard/WorkingRegulationMeetingManagement'));
const WorkingRegulationMeetingDetails = lazy(() => import('pages/dashboard/WorkingRegulationMeetingDetails'));


export const ALLOWED_ROLES = ['chukidan-sharoushi'];

const App = () => {
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const { token } = useAppSelector(authSelector);
    const dispatch = useAppDispatch();

    const onCloseForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(false);
        dispatch(reset());
    };

    const onOpenForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(true);
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force={true} />}>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home onOpenForgotPasswordModal={onOpenForgotPasswordModal} />} />
                        <Route path='forgot-password/:token/success' element={<ForgotPasswordSuccess />} />
                        <Route path='forgot-password/:token' element={<ForgotPassword />} />
                    </Route>
                    <Route path='/dashboard' element={<DashboardLayout />}>
                        <Route path='meeting' element={<MeetingCalendar />} />
                        <Route path='profile' element={<Profile />} />
                        <Route path='consultation'>
                            <Route index element={<ConsultationListCard />} />
                            <Route path=':uuid/details' element={<ConsultationDetails />} />
                        </Route>
                        <Route path='wr'>
                            <Route path='diagnose-management'>
                                <Route index element={<DiagnoseManagement />} />
                                <Route path=':id' element={<Diagnose />} />
                            </Route>
                            <Route path='meeting-management'>
                                <Route index element={<WorkRegulationMeetingManagement />} />
                                <Route path=':uuid' element={<WorkingRegulationMeetingDetails />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path='/forbidden' element={<Forbidden />} />
                </Routes>
            </Suspense>

            <ForgotPasswordModal isVisible={isForgotPasswordModalVisible} onClose={onCloseForgotPasswordModal} />
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
