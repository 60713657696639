import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetApplicationsRequest, getPolicyApplicationsApi, getPolicyApplicationStatusTypesApi } from 'api/application';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { RootState } from 'app/store';

export type { Label } from 'api/application';

// Pagination Data Structure returned by API call
export type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

// Policy Application Status Data Structure, mainly for Filter function
export type PolicyApplicationStatus = {
    code: string,
    name: string
}

/**
 * GET applications list
 **/
export const getPolicyApplications = createAsyncThunk(
    '/specialist/policies/applications',
    async (data: GetApplicationsRequest, { dispatch }) => {
        try {
            const response = await getPolicyApplicationsApi(data);
            dispatch(setPolicyApplications(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET policy application statuses list
 **/
export const getPolicyApplicationStatusTypes = createAsyncThunk(
    '/specialist/policies/applications/statuses',
    async (data, {dispatch}) => {
        try {
            const response = await getPolicyApplicationStatusTypesApi();
            dispatch(setPolicyApplicationStatusTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Create Application Slice
 */
export const policyApplicationListSlice = createSlice({
    name: 'policyApplicationList',
    initialState: {
        loading: true,
        policyApplicationStatusTypes: [] as PolicyApplicationStatus[],
        policyApplications: [] as PolicyApplicationDetails[],
        pagination: {} as Pagination,
    },
    reducers: {
        setPolicyApplications: (state, { payload }) => {
            state.policyApplications = payload.data;
            state.policyApplications.forEach((application: any) => {
                application.isDeadline = application.is_deadline;
                application.personInCharge = application.person_in_charge;
                application.createdAt = application.created_at;
            });

            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
        setPolicyApplicationStatusTypes: (state, { payload }) => {
            state.policyApplicationStatusTypes = payload.data;
        },
        resetPolicyApplications: (state) => {
            state.loading = true;
            state.policyApplications = [];
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        // getPolicyApplications action pending
        builder.addCase(getPolicyApplications.pending, (state) => {
            state.loading = true;
        });
        // getPolicyApplications action rejected
        builder.addCase(getPolicyApplications.rejected, (state) => {
            state.loading = false;
        });
        // getPolicyApplications action fulfilled
        builder.addCase(getPolicyApplications.fulfilled, (state) => {
            state.loading = false;
        });
    },
});

export const {
    setPolicyApplications,
    setPolicyApplicationStatusTypes,
    resetPolicyApplications,
} = policyApplicationListSlice.actions;
export const selectPolicyApplications = (state: RootState) => state.policyApplications;
