import { getAxiosClientWithToken } from './index';
import { AddCompanyPolicyCommentRequest, GetPolicyListRequest } from 'app/slice/policySlice';
import { UpdatePersonInChargeRequest } from '../app/slice/applicationSlice';

export const getPolicyListApi = (data: GetPolicyListRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/policies`, { params: data });
};

export const getCompanyPolicyCommentApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/policies/${uuid}/comment`);
};

export const addCompanyPolicyCommentApi = (request: AddCompanyPolicyCommentRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/policies/${request.uuid}/comment`, request);
};

export const getPolicyApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/policies/${uuid}`);
};

export const getPolicyStatusApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/policies/status`);
};

export const submitPolicyApi = (uuid: string, data: FormData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/specialist/policies/' + uuid, data, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteCompanyPolicyVerificationApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/specialist/policies/${uuid}`);
};

export const getCompanyPolicyLogsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/company-policy-logs/${uuid}`);
};

export const updateRepresentativeName = (request: UpdatePersonInChargeRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/policies/${request.uuid}/personInCharge`, { person_in_charge: request.personInCharge });
};