import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUserRegistrationData, registering } from 'api/user';

export type RegistrationFieldData = {
    'company[name]': string,
    'company[name_furigana]': string,
    'company[representative_position]': string,
    'profile[lastname]': string,
    'profile[firstname]': string,
    'profile[lastname_furigana]': string,
    'profile[firstname_furigana]': string,
    'profile[phone]': string,
    'user[email]': string,
    'company[postal_code]': string,
    'company[prefecture_code]': string,
    'company[address]': string,
    'company[building_name]': string,
    'company[industry_code]': string,
    'company[parttime_employee_count]': string,
    'company[employee_count_range_code]': string,
    'insurance_plans': string[],
    'company[labor_regulations_compliant]': number,
    'company[allow_sharoushi]': number,
    'company[allow_shindanshi]': number,
    'user[password]': string,
    'user[password_confirmation]': string,
}

export type RegistrationCredentials = {
    uuid: string,
    expires: string,
    signature: string
}

/**
 * GET user's registration data
 **/
export const getUserRegistrationData = createAsyncThunk(
    'registration/userData',
    async (data: RegistrationCredentials) => {
        const { uuid, expires, signature } = data;
        try {
            const response = await fetchUserRegistrationData(uuid, expires, signature);
            return response.data.data;
        } catch (err) {
            return false;
        }
    }
);

type RegistrationRequest = {
    nextUrl: string,
    formData: RegistrationFieldData
}

/**
 * PATCH user's registration data
 **/
export const registration = createAsyncThunk(
    'registration/registerData',
    async (data: RegistrationRequest, { rejectWithValue }) => {
        const { nextUrl, formData } = data;
        try {
            const response = await registering(nextUrl, formData);
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    }
);

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: {
        companyName: '',
        companyNameFurigana: '',
        companyRepresentativePosition: '',
        profileLastname: '',
        profileFirstname: '',
        profileLastnameFurigana: '',
        profileFirstnameFurigana: '',
        profilePhone: '',
        userEmail: '',
        companyPostalCode: '',
        companyPrefectureId: '',
        companyAddress: '',
        companyBuildingName: '',
        companyIndustryId: '',
        companyParttimeEmployeeCount: '',
        companyEmployeeCountRangeId: '',
        insurancePlan: [],
        companyLaborRegulationsComplaint: 0,
        companyAllowSharoushi: 0,
        companyAllowShindanshi: 0,
        userPassword: '',
        userPasswordConfirmation: '',
        uuid: '',
        expires: '',
        signature: '',
        nextUrl: '',
        success: false
    },
    reducers: {},
    extraReducers: (builder) => {
        // get user registration data action fulfilled
        builder.addCase(getUserRegistrationData.fulfilled, (state, { payload }) => {
            state.companyName = payload?.company?.name;
            state.profileFirstname = payload?.profile?.firstname;
            state.profileLastname = payload?.profile?.lastname;
            state.profileLastnameFurigana = payload?.profile?.lastname_furigana;
            state.profileFirstnameFurigana = payload?.profile?.firstname_furigana;
            state.userEmail = payload?.user?.email;
            state.nextUrl = payload?.url;
        });
        // register fulfilled
        builder.addCase(registration.fulfilled, (state) => {
            state.success = true;
        });
    }
});
