import { Button, Modal } from 'antd';
import { closeSuccessModal, successModalSelector } from 'app/slice/successSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Component used for all Success Modal
 * @return html
 **/
const SuccessModal = () => {
    const navigate = useNavigate();
    const { image = '', title, message, withButton, isShow, buttonLink, buttonTitle } = useAppSelector(successModalSelector);
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(closeSuccessModal());
    };

    const onConfirm = () => {
        onClose();

        if (buttonLink) {
            navigate(buttonLink);
        }
    };

    return (
        <>
            <Modal
                className='success-modal'
                visible={isShow}
                onCancel={onClose}
                maskClosable={false}
                keyboard={false}
                footer={null}
            >
                <div className='title'>{title}</div>
                {image && <img src={image} alt={'chukidan-icon'} />}
                <div className='message'>{message}</div>
                {withButton && (
                    <Button onClick={onConfirm} type='primary' className='mt-3 w-75'>
                        {buttonTitle}
                    </Button>
                )}
            </Modal>
        </>
    );
};

export default SuccessModal;
