import React from 'react';
import { selectLoader } from 'app/slice/loaderSlice';
import { useAppSelector } from 'app/hooks';

type Props = {
    force?: boolean
}

const Loader = (props: Props) => {
    const { force } = props;

    const loader = useAppSelector(selectLoader);
    const opacity = loader.count > 0 || force ? 1 : 0;

    return <div className='loader' style={{ opacity }} />;
};

export default Loader;
